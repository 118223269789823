<template>
  <div class="dashboard p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
        <h1 class="intro-section-title">Nazorg - Wmo/Wlz</h1>
        <div class="row">
          <p class="intro-section-text">
            Onderstaand dashboard geeft informatie weer over de <b>Wmo/Wlz</b> als onderdeel van de levenspadfase <b>Nazorg</b>. In dit dashboard wordt informatie weergegeven over mensen met dementie die overleden zijn. Er wordt gekeken naar de periode tot 3 jaar voor het overlijden. Er wordt onder andere gekeken of deze mensen met dementie enig gebruik hebben gemaakt van Wmo-zorg of van Wlz-zorg in natura met verblijf. Deze informatie is enkel beschikbaar op zorgkantoorregio-niveau. De weergegeven data is afkomstig uit het geselecteerde jaar.
          </p>
        </div>
      </div>
    </div>

     <div class="row" v-if="selectedLevel==='zorgkantoor'">
       <div class="col-sm-12 down">
         <card :cardId="'Gebruik van Wmo in de laatste levensjaren'" :hovertext="'Populatie: iedereen die in 2019 is overleden met de diagnose dementie. Van al deze personen is gekeken of zij in het jaar van overlijden of de drie jaar daarvoor op enig moment ondersteuning vanuit Wmo hebben ontvangen. Let op: mensen kunnen ook ondersteuning hebben ontvangen o.b.v. een andere indicatie dan dementie. De data over de mensen met dementie komt uit de cbs microdata.'" 
            :x="zorgkantoorregionaam_wmo" :y="percentage_enigGebruikWmo_table.map(value => `${value}%`)"
            :xtitle="'Regio naam'" :ytitle="'Percentage'" 
            :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
           <template slot="card-header">Gebruik van Wmo in de laatste levensjaren</template>
           <div slot="card-body">
             Het percentage van mensen met dementie dat in jaar van overlijden of de drie jaar daarvoor op enig moment ondersteuning uit Wmo hebben gekregen. 
             <stacked-bar-chart-colors v-if="load.graphOne" :showlegend="false" 
             :names="['Wmo']"
             :ytitle="'% gebruik Wmo'"
             :x="[zorgkantoorregionaam_wmo]"
             :y="[percentage_enigGebruikWmo]"
             :ticksuffix="'%'"/>
           </div>
         </card>
       </div>
     </div>

     <div class="row" v-if="selectedLevel==='dementienetwerk'">
       <div class="col-sm-12 down">
         <card-small :cardId="'Gebruik van Wmo in de laatste levensjaren'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
           <template slot="card-header">Gebruik van Wmo in de laatste levensjaren - uitsluitend op zorgkantoorregio-niveau</template>
         </card-small>
       </div>
     </div>


     <div class="row" v-if="selectedLevel==='zorgkantoor'">
       <div class="col-sm-12 down">
         <card :cardId="'Ooit opgenomen in verpleeghuis in laatste levensjaren'" :hovertext="'Populatie: iedereen die in 2019 is overleden met de diagnose dementie. Van al deze personen is gekeken of zij in het jaar van overlijden of de drie jaar daarvoor op enig moment zijn opgenomen in een verpleeghuis. Let op: mensen kunnen ook zijn opgenomen o.b.v. een andere indicatie dan dementie. De data over de mensen met dementie komt uit de cbs microdata.'" 
            :x="zorgkantoorregionaam_vh" :y="percentage_enigGebruikVerpleeghuis_table.map(value => `${value}%`)"
            :xtitle="'Regio naam'" :ytitle="'Percentage'" 
            :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
           <template slot="card-header">Ooit opgenomen in verpleeghuis in laatste levensjaren</template>
           <div slot="card-body">
             Het percentage van mensen met dementie dat in jaar van overlijden of de drie jaar daarvoor op enig moment zijn opgenomen in een verpleeghuis.
             <stacked-bar-chart-colors v-if="load.graphOne" 
             :showlegend="false" 
             :names="['verpleeghuis']"
             :ytitle="'% opgenomen in verpleeghuis'"
             :x="[zorgkantoorregionaam_vh]"
             :y="[percentage_enigGebruikVerpleeghuis]"
             :ticksuffix="'%'"/>
           </div>
         </card>
       </div>
     </div>

     <div class="row" v-if="selectedLevel==='dementienetwerk'">
       <div class="col-sm-12 down">
         <card-small :cardId="'Ooit opgenomen in verpleeghuis in laatste levensjaren'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
           <template slot="card-header">Ooit opgenomen in verpleeghuis in laatste levensjaren - uitsluitend op zorgkantoorregio-niveau</template>
         </card-small>
       </div>
     </div>

     <div class="row" v-if="selectedLevel==='zorgkantoor'">
       <div class="col-sm-12 down">
         <card :cardId="'Gemiddeld aantal dagen tussen eerste Wlz-opname en overlijden'" :hovertext="'Per regio wordt het gemiddeld aantal dagen tussen eerste opname en overlijden weergeven. De data over de mensen met dementie komt uit de cbs microdata. Populatie: iedereen die in het geselecteerde jaar is overleden en geïdentificeerd als persoon met dementie. Voor deze populatie wordt gekeken naar Wlz-gebruik in de periode 2017-2020, het gemiddeld aantal dagen is conditioneel op enig gebruik.'" 
            :x="zorgkantoorregionaam_eo" :y="gemDagenEersteOpname_table.map(value => `${value}`)"
            :xtitle="'Regio naam'" :ytitle="'Aantal'" 
            :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
           <template slot="card-header">Gemiddeld aantal dagen tussen eerste Wlz-opname en overlijden</template>
           <div slot="card-body">
             Gemiddeld aantal dagen tussen eerste Wlz-opname en overlijden voor personen met dementie die in {{selectedYear}} zijn overleden en gebruik maakten van Wlz-zorg in laatste levensjaren.
             <stacked-bar-chart-colors-numbers v-if="load.graphOne" :showlegend="false" :name1="'Eerste opname'"
             :ytitle="'Dagen tot eerste opname'"
             :tickangle ='45'
             :x1="zorgkantoorregionaam_eo"
             :y1="gemDagenEersteOpname"/>
           </div>
         </card>
       </div>
     </div>

     <div class="row" v-if="selectedLevel==='dementienetwerk'">
       <div class="col-sm-12 down">
         <card-small :cardId="'Dagen tot eerste opname'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
           <template slot="card-header">Dagen tot eerste opname - uitsluitend op zorgkantoorregio-niveau</template>
         </card-small>
       </div>
     </div>

     <div class="row" v-if="selectedLevel === 'landelijk'">
        <div class="col-sm-12">
          <div class="row">
           <div class="col-sm-6 down" >
              <card :cardId="'Gebruik van Wmo in de laatste levensjaren'" :hovertext="'Populatie: iedereen die in 2019 is overleden met de diagnose dementie. Van al deze personen is gekeken of zij in het jaar van overlijden of de drie jaar daarvoor op enig moment ondersteuning vanuit Wmo hebben ontvangen. Let op: mensen kunnen ook ondersteuning hebben ontvangen o.b.v. een andere indicatie dan dementie. De data over de mensen met dementie komt uit de cbs microdata.'">
                <template slot="card-header">Gebruik van Wmo in de laatste levensjaren</template>
                <div slot="card-body">
                  In onderstaande grafiek is landelijk het percentage mensen met dementie weergegeven dat op enig moment in het overlijdensjaar of in de drie jaar daarvoor steun uit de Wmo heeft ontvangen.
                    <stacked-bar-chart-colors v-if="load.graphTwo" :tickangle="0"
                    :isLandelijk='false'
                    :ytitle="'% gebruik Wmo'"
                    :x="[zorgkantoorregionaam_wmo]"
                    :y="[landelijk_enigGebruikWmo]"
                    :ticksuffix="'%'"
                    :showlegend="false" 
                    />
                </div>
              </card>
            </div>

            <div class="col-sm-6 down" v-if="selectedLevel === 'landelijk'">
              <card :cardId="'Ooit opgenomen in verpleeghuis in laatste levensjaren'" :hovertext="'Populatie: iedereen die in 2019 is overleden met de diagnose dementie. Van al deze personen is gekeken of zij in het jaar van overlijden of de drie jaar daarvoor op enig moment zijn opgenomen in een verpleeghuis. Let op: mensen kunnen ook zijn opgenomen o.b.v. een andere indicatie dan dementie. De data over de mensen met dementie komt uit de cbs microdata.'">
                <template slot="card-header">Ooit opgenomen in verpleeghuis in laatste levensjaren</template>
                <div slot="card-body">
                  Onderstaande grafiek toont het landelijke percentage mensen met dementie dat op enig moment in het jaar van overlijden of in de drie jaar daarvoor in een verpleeghuis is opgenomen.
                  <stacked-bar-chart-colors v-if="load.graphTwo" :tickangle="0"
                  :isLandelijk='false'
                  :ytitle="'% opgenomen in verpleeghuis'"
                  :x="[zorgkantoorregionaam_vh]"
                  :y="[landelijk_enigGebruikVerpleeghuis]"
                  :ticksuffix="'%'"
                  :showlegend="false" 
                  />
                </div>
              </card>
            </div>

          </div>
        </div>
      </div>

      <div class="row" v-if="selectedLevel === 'landelijk'">
       <div class="col-sm-6 down" >
          <card :cardId="'Gemiddeld aantal dagen tussen eerste Wlz-opname en overlijden'" :hovertext="'Per regio wordt het gemiddeld aantal dagen tussen eerste opname en overlijden weergeven. De data over de mensen met dementie komt uit de cbs microdata. Populatie: iedereen die in het geselecteerde jaar is overleden en geïdentificeerd als persoon met dementie. Voor deze populatie wordt gekeken naar Wlz-gebruik in de periode 2017-2020, het gemiddeld aantal dagen is conditioneel op enig gebruik.'">
            <template slot="card-header">Gemiddeld aantal dagen tussen eerste Wlz-opname en overlijden</template>
            <div slot="card-body">
              In onderstaande grafiek is het landelijke niveau weergegeven van het aantal dagen tussen de eerste Wlz-opname en het overlijden van mensen met dementie die zijn overleden en in de laatste jaren van hun leven gebruik hebben gemaakt van Wlz-zorg.
                <stacked-bar-chart-colors v-if="load.graphTwo" :tickangle="0"
                :isLandelijk='false'
                :ytitle="'Dagen tot eerste opname'"
                :x="[zorgkantoorregionaam_eo]"
                :y="[landelijk_gemDagenEersteOpname]"
                :showlegend="false" 
                />
            </div>
          </card>
        </div>
      </div>

     <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>

<script>


import Card from '../components/Card'
import CardSmall from '../components/CardSmall'
import StackedBarChartColors from '../components/StackedBarChartColors'
import StackedBarChartColorsNumbers from '../components/StackedBarChartColorsNumbers'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Nazorg - Wmo/Wlz',
  },
  components: { Card, CardSmall, StackedBarChartColors, StackedBarChartColorsNumbers, Footer},
  data () {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      load: {
        graphOne: false,
        graphTwo: false
      },
      zorgkantoorregionaam_wmo: [],
      zorgkantoorregionaam_vh: [],
      zorgkantoorregionaam_eo: [],
      gemDagenEersteOpname: [],
      percentage_enigGebruikVerpleeghuis: [],
      percentage_enigGebruikWmo: [],
      landelijk_gemDagenEersteOpname: [],
      landelijk_enigGebruikVerpleeghuis: [],
      landelijk_enigGebruikWmo: [],

      percentage_enigGebruikWmo_table:[],
      percentage_enigGebruikVerpleeghuis_table:[],
      gemDagenEersteOpname_table:[],
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  methods: {
    loadAllData (level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_us_zorggebruik/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a,b) => b.percentage_enigGebruikWmo-a.percentage_enigGebruikWmo)
          response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
          this.percentage_enigGebruikWmo = response.data.map(d => (d.percentage_enigGebruikWmo))
          this.percentage_enigGebruikWmo_table = response.data.map(d => (d.percentage_enigGebruikWmo).toFixed(1))
          this.zorgkantoorregionaam_wmo = response.data.map(d => d.zorgkantoorregionaam)
          response.data.sort((a,b) => b.percentage_enigGebruikVerpleeghuis-a.percentage_enigGebruikVerpleeghuis)
          this.percentage_enigGebruikVerpleeghuis = response.data.map(d => (d.percentage_enigGebruikVerpleeghuis))
          this.percentage_enigGebruikVerpleeghuis_table = response.data.map(d => (d.percentage_enigGebruikVerpleeghuis).toFixed(1))
          this.zorgkantoorregionaam_vh = response.data.map(d => d.zorgkantoorregionaam)
          response.data.sort((a,b) => b.gemDagenEersteOpname-a.gemDagenEersteOpname)
          this.gemDagenEersteOpname = response.data.map(d => (d.gemDagenEersteOpname).toFixed(0))
          this.gemDagenEersteOpname_table = response.data.map(d => (d.gemDagenEersteOpname).toFixed(1))
          this.zorgkantoorregionaam_eo = response.data.map(d => d.zorgkantoorregionaam)
          this.load.graphOne=true
        }
      )
      }
      else if(level === 'landelijk'){
        this.$http.get(`/api/zorgkantoor_us_zorggebruik/get_data/?jaar=${this.$store.state.selectedYear}&landelijk=True`).then(
        (response) => {
          response.data.sort((a,b) => b.percentage_enigGebruikWmo-a.percentage_enigGebruikWmo)
          response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
          this.landelijk_enigGebruikWmo = response.data.map(d => (d.percentage_enigGebruikWmo))
          this.zorgkantoorregionaam_wmo = response.data.map(d => d.zorgkantoorregionaam)
          response.data.sort((a,b) => b.percentage_enigGebruikVerpleeghuis-a.percentage_enigGebruikVerpleeghuis)
          this.landelijk_enigGebruikVerpleeghuis = response.data.map(d => (d.percentage_enigGebruikVerpleeghuis))
          this.zorgkantoorregionaam_vh = response.data.map(d => d.zorgkantoorregionaam)
          response.data.sort((a,b) => b.gemDagenEersteOpname-a.gemDagenEersteOpname)
          this.landelijk_gemDagenEersteOpname = response.data.map(d => (d.gemDagenEersteOpname))
          this.zorgkantoorregionaam_eo = response.data.map(d => d.zorgkantoorregionaam)

          this.landelijk_enigGebruikWmo_table = response.data.map(d => (d.percentage_enigGebruikWmo).toFixed(1))
          this.landelijk_gemDagenEersteOpname_table = response.data.map(d => (d.gemDagenEersteOpname).toFixed(1))
          this.landelijk_enigGebruikVerpleeghuis_table = response.data.map(d => (d.percentage_enigGebruikVerpleeghuis).toFixed(1))


          this.load.graphTwo=true
        }
      )
      }
    }
  },
  mounted () {
    this.loadAllData(this.selectedLevel)
  }
}

</script>

<style scoped>

.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

</style>